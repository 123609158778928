import { useEffect, useMemo } from 'react';
import { useIntl } from '@leagueplatform/locales';
import { getCoverages } from 'api/get-coverages';
import {
  APP_ROUTES,
  GENDER_ID_MAP,
  SHORT_DATE_FORMAT_UTC,
} from 'common/constants';
import { useQuery } from 'react-query';
import { useMemberOfInterestStore } from 'store/member-of-interest-store';
import { useHistory } from '@leagueplatform/routing';
import { groupCoverageByTenant } from 'utils/coverage-helpers';

export const MEMBER_DETAILS_QUERY_KEY = 'hm_member_details';

export type PersonalInfoType = {
  umi: string;
  firstName: string;
  lastName: string;
  dob: string;
  gender: string;
  relationship: string;
  ecId: string;
  loginId?: string;
  fullName: string;
  initials: string;
};

export type EligibleApps = {
  [key: string]: {
    labelId: string;
    ssoToolParamValue: string | null;
  };
};

export const useGetMemberDetails = () => {
  const { formatDate, formatMessage } = useIntl();
  const history = useHistory();

  const memberOfInterest = useMemberOfInterestStore((state) => state.member);

  useEffect(() => {
    if (!memberOfInterest) {
      history.push(APP_ROUTES.MEMBER_SEARCH);
    }
  }, [memberOfInterest, history]);

  const { data, isLoading, isError, error, ...query } = useQuery(
    [MEMBER_DETAILS_QUERY_KEY, memberOfInterest],
    () => {
      if (!memberOfInterest) {
        return null;
      }
      const { umi, dob, firstName, lastName } = memberOfInterest;

      return getCoverages({
        umi,
        dob,
        firstName,
        lastName,
      });
    },
    { enabled: !!memberOfInterest },
  );

  const coverageByTenant = useMemo(
    () =>
      (data &&
        memberOfInterest &&
        groupCoverageByTenant(data, memberOfInterest)) ||
      {},
    [data, memberOfInterest],
  );

  if (isLoading || isError || !data) {
    return {
      data: null,
      isLoading,
      isError,
      error,
      ...query,
    };
  }

  if (!memberOfInterest) {
    return {
      data: null,
      isLoading,
      isError,
      error,
      ...query,
    };
  }

  const personalInfo: PersonalInfoType = {
    ...memberOfInterest,
    dob: formatDate(memberOfInterest?.dob, SHORT_DATE_FORMAT_UTC),
    gender: formatMessage({
      id: GENDER_ID_MAP[memberOfInterest?.gender || 'U'],
    }),
    fullName: `${memberOfInterest?.firstName || ''} ${
      memberOfInterest?.lastName || ''
    }`,
    initials: `${memberOfInterest?.firstName[0] || ''}${
      memberOfInterest?.lastName[0] || ''
    }`,
  };

  return {
    data: {
      personalInfo,
      coverages: coverageByTenant,
    },
    isLoading,
    isError,
    error,
    ...query,
  };
};
